import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import validator from "validator";
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./authStyles.css";
import Cookies from "js-cookie";
import Loader from "../../../feature/loader/loader";

function Onboarding({ switchMode }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [strength, setStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showIndicator, setShowIndicator] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const containerClasses = location.pathname.includes("/onboarding")
    ? "mt-5 pt-5"
    : "";

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    ResidentialStatus: "",
    Country: "",
    Email: "",
    Password: "",
    MobileNumber: "",
    isWhatsappNumber: false,
    whatsappNumber: "",
  });

  const residentialStatusOptions = [
    { id: 1, value: "Residential Individual", label: "Residential Individual" },
    { id: 2, value: "Non-Resident Indian", label: "Non-Resident Indian" },
    { id: 3, value: "Foreign National", label: "Foreign National" },
    {
      id: 4,
      value: "Person of Indian Origin",
      label: "Person of Indian Origin",
    },
  ];

  const residentialStatusDefinitions = {
    "Residential Individual":
      "Indian citizen living in India & tax status resident of India.",
    "Non-Resident Indian":
      "Indian citizen living abroad & tax status non-resident.",
    "Foreign National":
      "Foreign citizen living abroad & tax status non-resident.",
    "Person of Indian Origin":
      "Foreign citizen (except nationals of Pakistan, Afghanistan Bangladesh, China, Iran, Bhutan, Sri Lanka and Nepal) who at any time held an Indian Passport or either of parents, grandparents or great grandparents were born in India.",
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/countries`)
      .then((response) =>
        setCountries(
          response.data.map((country) => ({
            value: country.CountryId,
            label: country.Name,
          }))
        )
      )
      .catch((error) => console.error("Error fetching countries:", error));
  }, [baseUrl]);

  useEffect(() => {
    const checkFormValidity = () => {
      const isValid =
        formData.FirstName?.trim() &&
        formData.LastName?.trim() &&
        validator.isEmail(formData.Email) &&
        !emailExists &&
        formData.Password &&
        strength >= 3 &&
        !formErrors.FirstName &&
        !formErrors.LastName;
      setFormValid(isValid);
    };
    checkFormValidity();
  }, [formData, emailExists, strength, formErrors]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/loading");
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let newValue = value;

    if (name === "FirstName" || name === "LastName") {
      newValue = value.replace(/[^A-Za-z]/g, "").toUpperCase();
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : newValue,
    }));

    if (!newValue?.trim() && type !== "checkbox") {
      setFormErrors((prev) => ({ ...prev, [name]: "This field is required" }));
    } else {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleEmailChange = async (event) => {
    const email = event.target.value;
    setFormData((prev) => ({ ...prev, Email: email }));

    if (!email) {
      setFormErrors((prev) => ({ ...prev, Email: "Email is required" }));
    } else if (!validator.isEmail(email)) {
      setFormErrors((prev) => ({ ...prev, Email: "Invalid email address" }));
    } else {
      setFormErrors((prev) => ({ ...prev, Email: "" }));
      try {
        const response = await axios.get(
          `${baseUrl}/email-check?Email=${email}`
        );
        setEmailExists(response.data.EmailExists);
        if (response.data.EmailExists) {
          setFormErrors((prev) => ({
            ...prev,
            Email: "Email already exists",
          }));
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData((prev) => ({ ...prev, Country: selectedOption.value }));

    // Update phone code based on selected country
    const countryCode = getCountryCallingCode(selectedOption.value);
    setFormData((prev) => ({
      ...prev,
      MobileNumber: `+${countryCode}`,
      whatsappNumber: prev.isWhatsappNumber
        ? `+${countryCode}`
        : prev.whatsappNumber,
    }));
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    switch (name) {
      case "MobileNumber":
        if (!value) errorMsg = "This field is required";
        if (!validator.isMobilePhone(value)) errorMsg = "Invalid mobile number";
        break;
      case "whatsappNumber":
        if (!formData.isWhatsappNumber && !validator.isMobilePhone(value))
          errorMsg = "Invalid WhatsApp number";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };

  const handleWhatsappNumberChange = (value) => {
    setFormData((prevData) => ({ ...prevData, whatsappNumber: value || "" }));
  };

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      isWhatsappNumber: checked,
      whatsappNumber: checked ? prevData.MobileNumber : prevData.whatsappNumber,
    }));
  };

  const calculatePasswordStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score++;
    if (/\d/.test(password)) score++;
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score++;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score++;
    setStrength(score);
    setShowIndicator(true);
  };

  const getPasswordStrengthLabel = (score) => {
    if (score === 0) return "Very Weak";
    if (score === 1) return "Weak";
    if (score === 2) return "Fair";
    if (score === 3) return "Good";
    if (score === 4) return "Strong";
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    const requiredFields = [
      "FirstName",
      "LastName",
      "ResidentialStatus",
      "Country",
      "Email",
      "Password",
      "MobileNumber",
    ];

    let hasErrors = false;

    requiredFields.forEach((field) => {
      const value = formData[field];
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
        }));
      }
    });

    if (!formData.isWhatsappNumber && !formData.whatsappNumber) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        whatsappNumber: "This field is required",
      }));
      hasErrors = true;
    }

    if (strength < 3) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Password: "Password is too weak. Please choose a stronger password.",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formDataWithWhatsapp = {
        ...formData,
        whatsappNumber: formData.isWhatsappNumber
          ? formData.MobileNumber
          : formData.whatsappNumber,
      };

      if (formValid) {
        setIsLoading(true);
        axios
          .post(`${baseUrl}/signup`, formDataWithWhatsapp)
          .then(async ({ data }) => {
            const userData = data.userData;
            sessionStorage.setItem("userData", JSON.stringify(userData));
            toast.success(
              "Account registered successfully. Please check your email for verification."
            );

            // Check KYC status
            const kycResponse = await axios.get(`${baseUrl}/getUserData`, {
              headers: { WRGSJGEUWCQN58EGV92D: `Bearer ${data.token}` },
            });
            const kycStatus = kycResponse.data.members?.KYCStatus || 0;

            if (kycStatus === 1 || kycStatus === 3) {
              navigate("/dashboard");
            } else {
              navigate("/profile-process#kyc-check");
            }
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.error || "An unexpected error occurred";
            toast.error(` ${errorMessage}`);
            console.error("Error during registration:", error);
          })
          .finally(() => setIsLoading(false));
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "An unexpected error occurred";
      toast.error(` ${errorMessage}`);
      setIsLoading(false);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      const { status, platform, deviceName, locationIp } =
        await createAndSendUserSessionData();
      const redirectUrl = `${baseUrl}/auth/google?status=${status}&platform=${platform}&deviceName=${deviceName}&locationIp=${locationIp}`;

      const newWindow = window.open(
        redirectUrl,
        "GoogleLogin",
        "width=600,height=600"
      );

      const receiveMessage = (event) => {
        if (event.origin !== baseUrl) return;
        if (event.data.success) {
          newWindow.close();
          toast.success("Login successful!");
        } else {
          toast.error("Google login failed.");
        }
        window.removeEventListener("message", receiveMessage);
      };

      window.addEventListener("message", receiveMessage);
    } catch (error) {
      console.error("Error in handleLogin:", error);
    }
  };

  const handleLoginClick = () => {
    if (switchMode) {
      switchMode("signin");
    } else {
      navigate("/sign-in");
    }
  };

  // Helper function to get country calling code (you'll need to implement this)
  const getCountryCallingCode = (countryId) => {
    // Implement logic to return the calling code for the given country ID
    // This might involve maintaining a separate mapping of country IDs to calling codes
    return "91"; // Default to India's code for this example
  };

  // Helper function to create and send user session data
  const createAndSendUserSessionData = async () => {
    const status = "active";
    const platform = typeof window.MyAppBridge !== "undefined" ? "app" : "web";
    const deviceName =
      navigator.userAgent.match(/\(([^)]+)\)/)?.[1] || "Unknown";
    const locationIp = await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip)
      .catch(() => "0.0.0.0");

    return { status, platform, deviceName, locationIp };
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container className={containerClasses}>
      <Row className="align-items-center">
        <h3 className={`text-center mt-4 formHeading`}>
          Unlock India's Potential
        </h3>
        <div body className="formComponent">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-form">
              <Col md={6} className={`formInputItem`}>
                <Form.Group className="mb-1" controlId="fname">
                  <Form.Label>
                    First Name (as per PAN)<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="FirstName"
                    value={formData.FirstName}
                    onChange={handleInputChange}
                    isInvalid={!!formErrors.FirstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.FirstName}
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    Only letters are allowed. Name will be automatically
                    converted to capital letters.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-1" controlId="lname">
                  <Form.Label>
                    Last Name (as per PAN)<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="LastName"
                    value={formData.LastName}
                    onChange={handleInputChange}
                    isInvalid={!!formErrors.LastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.LastName}
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    Only letters are allowed. Name will be automatically
                    converted to capital letters.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-form">
              <Col md={6}>
                <Form.Group
                  className="mb-1 react-select"
                  controlId="residential"
                >
                  <Form.Label>
                    Residential Status<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Select
                    options={residentialStatusOptions}
                    value={residentialStatusOptions.find(
                      (option) => option.value === formData.ResidentialStatus
                    )}
                    onChange={(option) =>
                      setFormData((prev) => ({
                        ...prev,
                        ResidentialStatus: option.value,
                      }))
                    }
                    name="ResidentialStatus"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-1 react-select" controlId="country">
                  <Form.Label>
                    Country<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Select
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    name="Country"
                    required
                    filterOption={(option, inputValue) =>
                      option.label
                        .toLowerCase()
                        .startsWith(inputValue.toLowerCase())
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-form p-0 m-0">
              <Col md={6}>
                <Form.Group controlId="formGridMobileNumber">
                  <Form.Label>Mobile Number*</Form.Label>
                  <PhoneInput
                    country={selectedCountry ? selectedCountry.value : "IN"}
                    name="MobileNumber"
                    className={`form-control ${
                      errors.MobileNumber ? "is-invalid" : ""
                    }`}
                    value={formData.MobileNumber}
                    onChange={(value) =>
                      handleInputChange({
                        target: { name: "MobileNumber", value },
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.MobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {!formData.isWhatsappNumber && (
                <Col md={6}>
                  <Form.Group controlId="formGridWhatsappNumber">
                    <Form.Label>WhatsApp Number</Form.Label>
                    <PhoneInput
                      country={selectedCountry ? selectedCountry.value : "IN"}
                      name="whatsappNumber"
                      className={`form-control ${
                        errors.whatsappNumber ? "is-invalid" : ""
                      }`}
                      value={formData.whatsappNumber}
                      onChange={handleWhatsappNumberChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.whatsappNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row className="mb-form">
              <Col md={12}>
                <Form.Group className="mb-1" controlId="email">
                  <Form.Label>
                    Email Address<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    required
                    name="Email"
                    value={formData.Email}
                    onChange={handleEmailChange}
                    isInvalid={!!formErrors.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-1 psw-filed" controlId="password">
                  <Form.Label>
                    Create Password<span className={`asterisk`}>*</span>
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      required
                      name="Password"
                      value={formData.Password}
                      onChange={(e) => {
                        handleInputChange(e);
                        calculatePasswordStrength(e.target.value);
                      }}
                      maxLength={50}
                      isInvalid={!!errors.Password}
                    />
                    <span
                      className="psw-icon"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "38%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <BiShow size={30} />
                      ) : (
                        <BiHide size={30} />
                      )}
                    </span>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.Password}
                  </Form.Control.Feedback>
                  {showIndicator && (
                    <div className="password-strength-indicator">
                      <div
                        className={`strength-bar strength-${strength}`}
                      ></div>
                      <span>{getPasswordStrengthLabel(strength)}</span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="small-text mt-3 text-center">
                  By clicking Create Account, I accept{" "}
                  <Link to="/terms&conditions">Terms</Link> and{" "}
                  <Link to="/user-policy">User Policy</Link>
                </p>
                <button
                  type="submit"
                  className={`submitButton d-block ${
                    !formValid ? "disabled" : ""
                  }`}
                  disabled={!formValid}
                >
                  Create Account
                </button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <h5>Residential Status Definitions:</h5>
                <ul>
                  {Object.entries(residentialStatusDefinitions).map(
                    ([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    )
                  )}
                </ul>
              </Col>
            </Row>
            <Row>
              <div className="text-center">
                <p className={`d-flex justify-content-center m-1`}>or</p>
                <Col md={12} className="d-flex justify-content-center">
                  <div
                    className={`googleButton my-3`}
                    onClick={handleLoginWithGoogle}
                  >
                    <span>
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src="/images/googleIcon.svg"
                        alt="login with google"
                      />
                      Sign up with Google
                    </span>
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div className="text-center">
                <p className={`d-flex justify-content-center m-1`}>
                  Already have account?
                </p>
                <Col md={12} className="d-flex justify-content-center">
                  <Col className="arrowRightSvg" onClick={handleLoginClick}>
                    <span className={`toSignIn`}>Login to your account</span>
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </Col>
                </Col>
              </div>
            </Row>
          </Form>
        </div>
      </Row>
    </Container>
  );
}

export default Onboarding;
