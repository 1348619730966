import "./css/style.css";
import { Row, Col } from "react-bootstrap";
import FaqsSection from "../modules/faqSection/faqSection";
import AmInvestBanner from "../modules/bannerSection/bannerSection";
import AmInvestStockBundle from "../modules/stockModules/stockModules";
import NewsSection from "../modules/newsSection/newsSection";
import AmInvestCard from "../components/cards/amInvestCards";
import VisionStatement from "../modules/visionStatementSection/visionStatement";
import CalculatorSection from "../modules/calculatorSection/calculatorSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import WhatsAppCommunityBanner from "../components/communityBanner/communityBanner";
import AmInvestButtons from "../components/buttons/amInvestButton";
import Cookies from "js-cookie";
import AuthModal from "../modules/authModule/authModal";
import { useNavigate } from "react-router-dom";

const MyComponent = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [blogEntries, setBlogEntries] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [authMode, setAuthMode] = useState("signin");
  useEffect(() => {
    const token = Cookies.get("token");
    setIsLoggedIn(!!token);
  }, []);
  const handleOpenModal = (mode) => {
    setAuthMode(mode);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleSignInSuccess = () => {
    setShowModal(false);
    setIsLoggedIn(true);
    navigate("/dashboard");
  };
  useEffect(() => {
    fetch(`${baseUrl}/blogs`)
      .then((response) => response.json())
      .then((data) => setBlogEntries(data.blogs))
      .then((data) => {
        const sortedBlogs = data.blogs
          .slice(0, 4)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
        setBlogEntries(sortedBlogs);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://ambassadorinvestments.com/" />
      </Helmet>

      <AmInvestBanner />
      <AmInvestStockBundle />
      <CalculatorSection />
      <section className={`learnAndInvest`}>
        <div>
          {isLoggedIn ? (
            <Link
              to="/dashboard"
              className="goToProfileButton"
              id="profileButton"
            >
              Go to Dashboard
            </Link>
          ) : (
            <AmInvestButtons
              title="Start Investing"
              buttonType="dark"
              onClick={() => handleOpenModal("signin")}
              id="loginButton"
            />
          )}
        </div>

        <p className={`subTitleText my-2`}>
          Learn about market and strategies{" "}
        </p>

        <section className={`blogsSection container`}>
          <Row className={`blogRow`}>
            {blogEntries.slice(0, 4).map((blog, index) => (
              <Col key={index} sm={10} md={3} className="px-2 mb-4">
                <Link className={`d-flex`} to={`/blogs/${blog.slug}`}>
                  <AmInvestCard
                    type="blogCard"
                    image={blog.image}
                    title={blog.title}
                    description={blog.description}
                    date={blog.date}
                    readTime={`${blog.readTime} `}
                  />
                </Link>
              </Col>
            ))}
          </Row>
        </section>
        <Col className="exploreButton arrowRightSvg d-flex justify-content-center mb-5">
          <a href="/blogs" className="cta">
            <span>Read More</span>
            <svg width="13px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </a>
        </Col>
      </section>
      <NewsSection baseUrl={baseUrl} />
      <VisionStatement />
      <WhatsAppCommunityBanner />
      <FaqsSection />
      <AuthModal
        show={showModal}
        onHide={handleCloseModal}
        mode={authMode}
        onSignInSuccess={handleSignInSuccess}
      />
    </>
  );
};

export default MyComponent;
